// import { localStoragePrefix } from '../config';
// import { TOKEN } from './constants';

export default {
  // authStatus: '',
  // token: localStorage.getItem(localStoragePrefix + TOKEN) || null,
  // user: {},
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage:
    'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
  drawer: null,
  // isAuthenticated: localStorage[localStoragePrefix + 'TOKEN'] ? true : false,
};
