import ApiService from '@/services/api.service';
import JwtService from '@/services/jwt.service';
import {
  PURGE_AUTH,
  SET_AUTH,
  SET_ERROR,
  UPDATE_USER,
  VERIFY_AUTH,
} from './constants';

export default {
  // async login({ commit }, credentials) {
  //   try {
  //     const { data } = await ApiService.post('auth/authenticate', credentials);
  //     console.log({ login: data.data });
  //     return commit(SET_AUTH, data.data);
  //   } catch (error) {
  //     console.log('error', error);
  //     commit(SET_ERROR, error);
  //     return;
  //   }
  // },
  // signout({ commit }) {
  //   commit(PURGE_AUTH);
  // },
  // async signup({ commit }, credentials) {
  //   try {
  //     // register user
  //     commit(USER_SIGNUP_REQUEST)
  //     const { data } = await ApiService.post('users', credentials);
  //     console.log('signup res', data.data);
  //   } catch (error) {
  //     console.log(error);
  //     return commit(SET_ERROR, error);
  //   }
  // },
  // [VERIFY_AUTH](context) {
  //   if (JwtService.getToken()) {
  //     ApiService.setHeader();
  //     ApiService.get('users/me')
  //       .then(() => {
  //       })
  //       .catch(({ data }) => {
  //         console.log(data);
  //       });
  //   } else {
  //     commit(PURGE_AUTH);
  //   }
  // },
  // async [UPDATE_USER](context, payload) {
  //   try {
  //     const { email, username, password, image } = payload;
  //     const user = { email, username, image };
  //     if (password) {
  //       user.password = password;
  //     }
  //     const { data } = await ApiService.put('user', user);
  //     commit(SET_AUTH, data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // },
};
