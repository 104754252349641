import { localStoragePrefix } from '../config';
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from './constants';
import JwtService from '../services/jwt.service';

// export const PURGE_AUTH = 'logOut';
// export const SET_AUTH = 'setUser';
// export const SET_ERROR = 'setError';

export default {
  // auth_request(state) {
  //   state.authStatus = 'loading';
  // },
  // auth_success(state, { token, user }) {
  //   state.authStatus = 'success';
  //   state.token = token;
  //   state.user = user;
  // },
  // auth_error(state) {
  //   state.authStatus = 'error';
  // },
  // logout(state) {
  //   state.authStatus = '';
  //   state.token = '';
  // },

  // ===
  SET_BAR_IMAGE(state, payload) {
    state.barImage = payload;
  },
  SET_DRAWER(state, payload) {
    state.drawer = payload;
  },

  // [SET_ERROR](state, error) {
  //   return (state.errors = error);
  // },
  // [SET_AUTH](state, authData) {
  //   state.isAuthenticated = true;
  //   state.user = authData.user;
  //   state.errors = {};
  //   JwtService.saveToken(authData.token);
  //   localStorage[localStoragePrefix + 'userInfo'] = JSON.stringify(
  //     authData.user
  //   );
  // },
  // [PURGE_AUTH](state) {
  //   //console.log("i was called");
  //   state.isAuthenticated = false;
  //   state.user = {};
  //   state.errors = {};
  //   JwtService.destroyToken();
  // },
};
