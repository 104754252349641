import { localStoragePrefix } from '@/config';
import { PURGE_AUTH, SET_AUTH, SET_ERROR } from '../constants';
import JwtService from '@/services/jwt.service';
import {
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
  USER_SIGNIN_REQUEST,
  USER_SIGNIN_SUCCESS,
  USER_SIGNIN_FAIL,
  USER_SIGNUP_FAIL,
} from '../constants/user.constants';

export default {
  auth_request(state) {
    state.authStatus = 'loading';
  },
  auth_success(state, { token, user }) {
    state.authStatus = 'success';
    state.token = token;
    state.user = user;
  },
  auth_error(state) {
    state.authStatus = 'error';
  },
  logout(state) {
    state.authStatus = '';
    state.token = '';
  },

  // ===
  // SET_BAR_IMAGE(state, payload) {
  //   state.barImage = payload;
  // },
  // SET_DRAWER(state, payload) {
  //   state.drawer = payload;
  // },

  // [SET_ERROR](state, error) {
  //   return (state.errors = error);
  // },

  [USER_SIGNIN_FAIL](state, error) {
    return (state.errorLogin = error);
  },

  [USER_SIGNUP_FAIL](state, error) {
    return (state.errorSignup = error);
  },

  [USER_SIGNIN_REQUEST](state) {
    return (state.loadingLogin = true);
  },

  [USER_SIGNUP_REQUEST](state) {
    return (state.loadingSignup = true);
  },

  [USER_SIGNUP_SUCCESS](state) {
    return (state.loadingSignup = true);
  },

  [USER_SIGNIN_SUCCESS](state, authData) {
    state.isAuthenticated = true;
    state.user = authData.user;
    state.errors = {};
    JwtService.saveToken(authData.token);
    localStorage[localStoragePrefix + 'userInfo'] = JSON.stringify(
      authData.user
    );
    state.loadingLogin = false;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
};
