// export default {
//   authorized: (state) => !!state.token,
//   authstatus: (state) => state.authStatus,
//   currentUser: (state) => state.user,
//   isAuth: (state) => state.isAuthenticated,
//   userGetter: (state) => {
//     return {
//       isAuth: state.isAuth,
//       loading: state.loading,
//       error: state.error,
//       // isAuth: state.isAuth,
//     };
//   },
// };

export default {
  // authorized: (state) => !!state.token,
  authstatus: (state) => state.authStatus,
  // currentUser: (state) => state.user,
  // isAuth: (state) => state.isAuthenticated,
  userGetter: (state) => {
    return {
      loadingLogin: state.loadingLogin,
      errorSignup: state.errorSignup,
      isAuth: state.isAuthenticated,
      currentUser: state.user,
      authorized: !!state.token,
    };
  },
};
